import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import matchSorter from "match-sorter";

class GroupCollectionPeopleList extends React.Component {
  render() {
    const data = this.props.people;

    const paymentColumn = {
      Header: "Paid",
      accessor: "discrepancy",
      Cell: ({ value }) => {
        if (value == 0.0) {
          return <span>&#9989;</span>;
        } else {
          return <span>&#x1F6AB;</span>;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }

        if (filter.value === "good" && row[filter.id] == 0.0) {
          return true;
        }

        if (filter.value === "issue" && row[filter.id] != 0.0) {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="issue">&#x1F6AB;</option>
          <option value="good">&#9989;</option>
        </select>
      ),
      filterable: true,
      width: 75
    };

    let columns = [
      {
        Header: "Email",
        accessor: "email",
        filterable: true
      },
      {
        id: "name",
        Header: "Name",
        accessor: (d) => d.first_name + " " + d.last_name,
        filterable: true
      },
      {
        Header: "Phone",
        accessor: "phone",
        filterable: true,
        width: 140
      },
      {
        Header: "Group",
        accessor: "group_name",
        filterable: true
      },
      {
        Header: "Event",
        accessor: "events",
        Cell: ({ value }) => {
          if (value.length === 0) {
            return null;
          }

          if (value.length === 1) {
            return value[0].name;
          }

          return value.map((event) => <div key={event.id}>{event.name}</div>);
        },
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          const event_ids = row[filter.id].map((event) => {
            return event.id;
          });

          return event_ids.includes(parseInt(filter.value));
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            {this.props.events.map((state) => (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            ))}
          </select>
        ),
        width: 120
      },
      {
        Header: "Flags",
        accessor: "flagged",
        Cell: ({ value }) => !!value ? "Yes" : "No",
        filterMethod: (filter, row) => filter.value === "all" || row[filter.id] === (filter.value === "yes"),
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        ),
        width: 120
      },
      {
        Header: "",
        accessor: "links",
        width: 90,
        Cell: ({ value }) => (
          <div className="clearfix">
            <div className="float-right">
              {value["show"] != null && (
                <a href={value["show"]} className="action-button green">
                  <i
                    className="fi-magnifying-glass tooltips"
                    data-original-title="Person Details"
                    title="Person Details"
                  />
                </a>
              )}

              {value["move"] != null && (
                <a href={value["move"]} className="action-button">
                  <i
                    className="fi-torsos-all tooltips"
                    data-original-title="Move Groups"
                    title="Move Groups"
                  />
                </a>
              )}
              {value["move"] == null && (
                <div href={value["move"]} className="action-button disabled">
                  <i
                    className="fi-torsos-all tooltips"
                    data-original-title="Finalized Group"
                    title="Finalized Group"
                  />
                </div>
              )}
            </div>
          </div>
        ),
        filterable: false
      },
    ];

    if (this.props.displayPayment) {
      columns.splice(5, 0, paymentColumn);
    }

    return (
      <ReactTable
        data={data}
        columns={columns}
        className="-striped -highlight"
        filterable
        defaultFiltered={this.props.defaultFilters}
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        }
      />
    );
  }
}

GroupCollectionPeopleList.propTypes = {
  people: PropTypes.array,
  events: PropTypes.array,
  defaultFilters: PropTypes.array,
};
export default GroupCollectionPeopleList;
